import { getField, updateField } from "vuex-map-fields";
export const state = () => ({
    _id: null,
    name: null,
    title: null,
    description: null,
    type: "MultiPolygon",
    locales: {},
})

export const mutations = {
    updateField,
    setClassification(state, classification) {
        state._id = classification._id;
        state.title = classification.title;
        state.name = classification.name;
        state.description = classification.description;
        state.type = classification.type ?? "MultiPolygon";
    },
    setLocales(state, locales) {
        state.locales = locales;
    },
    updateClassificationName(state, value) {
        state.name[value.key] = value.value;
    },
}
export const getters = {
    getField
};