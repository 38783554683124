export const state = () => ({
  project: {},
  tsr: {},
  ts: {},
  orgs: {}
})

export const mutations = {
  setProject(state, project) {
    state.project = project;
  },
  setTS(state, ts) {
    state.ts = ts;
  },
  setTSR(state, tsr) {
    state.tsr = tsr;
  },
  setOrgs(state, orgs) {
    state.orgs = orgs;
  }
}