//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      routePath: this.$route.path,
      user_name: this.$auth.user.name,
    };
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      this.$router.push("/auth/login");
    },
    activeRoute(route) {
      if (this.routePath.indexOf(route) !== -1) {
        return true;
      }
    },
    hasPermission(permission) {
      return this.$auth.user.permissions.some((p) => p.name == permission);
    },
  },
};
