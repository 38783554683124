import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Activity: () => import('../../components/Activity.vue' /* webpackChunkName: "components/activity" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  FormulaConstructor: () => import('../../components/Formula/Constructor.vue' /* webpackChunkName: "components/formula-constructor" */).then(c => wrapFunctional(c.default || c)),
  FormulaExpression: () => import('../../components/Formula/Expression.vue' /* webpackChunkName: "components/formula-expression" */).then(c => wrapFunctional(c.default || c)),
  FormulaExpressionWithBlock: () => import('../../components/Formula/ExpressionWithBlock.vue' /* webpackChunkName: "components/formula-expression-with-block" */).then(c => wrapFunctional(c.default || c)),
  FormulaExpressionsBlock: () => import('../../components/Formula/ExpressionsBlock.vue' /* webpackChunkName: "components/formula-expressions-block" */).then(c => wrapFunctional(c.default || c)),
  FormulaInputVarField: () => import('../../components/Formula/InputVarField.vue' /* webpackChunkName: "components/formula-input-var-field" */).then(c => wrapFunctional(c.default || c)),
  Graph: () => import('../../components/Graph/index.vue' /* webpackChunkName: "components/graph" */).then(c => wrapFunctional(c.default || c)),
  LayerGroupCreate: () => import('../../components/LayerGroup/Create.vue' /* webpackChunkName: "components/layer-group-create" */).then(c => wrapFunctional(c.default || c)),
  LayerGroupLayers: () => import('../../components/LayerGroup/Layers.vue' /* webpackChunkName: "components/layer-group-layers" */).then(c => wrapFunctional(c.default || c)),
  LayerGroupUpdate: () => import('../../components/LayerGroup/Update.vue' /* webpackChunkName: "components/layer-group-update" */).then(c => wrapFunctional(c.default || c)),
  NodeAutonode: () => import('../../components/Node/Autonode.vue' /* webpackChunkName: "components/node-autonode" */).then(c => wrapFunctional(c.default || c)),
  NodeAutonodeFormula: () => import('../../components/Node/AutonodeFormula.vue' /* webpackChunkName: "components/node-autonode-formula" */).then(c => wrapFunctional(c.default || c)),
  NodeCopyTsr: () => import('../../components/Node/CopyTsr.vue' /* webpackChunkName: "components/node-copy-tsr" */).then(c => wrapFunctional(c.default || c)),
  NodeCreate: () => import('../../components/Node/Create.vue' /* webpackChunkName: "components/node-create" */).then(c => wrapFunctional(c.default || c)),
  NodeReturnModal: () => import('../../components/Node/ReturnModal.vue' /* webpackChunkName: "components/node-return-modal" */).then(c => wrapFunctional(c.default || c)),
  NodeUpdate: () => import('../../components/Node/Update.vue' /* webpackChunkName: "components/node-update" */).then(c => wrapFunctional(c.default || c)),
  ProjectDeploy: () => import('../../components/Project/Deploy.vue' /* webpackChunkName: "components/project-deploy" */).then(c => wrapFunctional(c.default || c)),
  ProjectInfo: () => import('../../components/Project/Info.vue' /* webpackChunkName: "components/project-info" */).then(c => wrapFunctional(c.default || c)),
  ProjectLayers: () => import('../../components/Project/Layers.vue' /* webpackChunkName: "components/project-layers" */).then(c => wrapFunctional(c.default || c)),
  ProjectModules: () => import('../../components/Project/Modules.vue' /* webpackChunkName: "components/project-modules" */).then(c => wrapFunctional(c.default || c)),
  ProjectOrgByTerritories: () => import('../../components/Project/OrgByTerritories.vue' /* webpackChunkName: "components/project-org-by-territories" */).then(c => wrapFunctional(c.default || c)),
  ProjectOrgUpdateModal: () => import('../../components/Project/OrgUpdateModal.vue' /* webpackChunkName: "components/project-org-update-modal" */).then(c => wrapFunctional(c.default || c)),
  ProjectRegistryList: () => import('../../components/Project/RegistryList.vue' /* webpackChunkName: "components/project-registry-list" */).then(c => wrapFunctional(c.default || c)),
  ProjectReplicate: () => import('../../components/Project/Replicate.vue' /* webpackChunkName: "components/project-replicate" */).then(c => wrapFunctional(c.default || c)),
  ProjectSelectServiceModal: () => import('../../components/Project/SelectServiceModal.vue' /* webpackChunkName: "components/project-select-service-modal" */).then(c => wrapFunctional(c.default || c)),
  ProjectTS: () => import('../../components/Project/TS.vue' /* webpackChunkName: "components/project-t-s" */).then(c => wrapFunctional(c.default || c)),
  ProjectUpdate: () => import('../../components/Project/Update.vue' /* webpackChunkName: "components/project-update" */).then(c => wrapFunctional(c.default || c)),
  ServiceList: () => import('../../components/Service/List.vue' /* webpackChunkName: "components/service-list" */).then(c => wrapFunctional(c.default || c)),
  ClassificationModalCreate: () => import('../../components/Classification/Modal/Create.vue' /* webpackChunkName: "components/classification-modal-create" */).then(c => wrapFunctional(c.default || c)),
  ClassificationModalUpdate: () => import('../../components/Classification/Modal/Update.vue' /* webpackChunkName: "components/classification-modal-update" */).then(c => wrapFunctional(c.default || c)),
  AttributeModalCreate: () => import('../../components/Attribute/Modal/Create.vue' /* webpackChunkName: "components/attribute-modal-create" */).then(c => wrapFunctional(c.default || c)),
  AttributeModalEdit: () => import('../../components/Attribute/Modal/Edit.vue' /* webpackChunkName: "components/attribute-modal-edit" */).then(c => wrapFunctional(c.default || c)),
  CoreModalCreate: () => import('../../components/Core/Modal/Create.vue' /* webpackChunkName: "components/core-modal-create" */).then(c => wrapFunctional(c.default || c)),
  CoreModalUpdate: () => import('../../components/Core/Modal/Update.vue' /* webpackChunkName: "components/core-modal-update" */).then(c => wrapFunctional(c.default || c)),
  DataCatalogValue: () => import('../../components/Data/Catalog/Value.vue' /* webpackChunkName: "components/data-catalog-value" */).then(c => wrapFunctional(c.default || c)),
  DocModalInputCreate: () => import('../../components/Doc/Modal/InputCreate.vue' /* webpackChunkName: "components/doc-modal-input-create" */).then(c => wrapFunctional(c.default || c)),
  DocModalInputQR: () => import('../../components/Doc/Modal/InputQR.vue' /* webpackChunkName: "components/doc-modal-input-q-r" */).then(c => wrapFunctional(c.default || c)),
  DocModalInputUpdate: () => import('../../components/Doc/Modal/InputUpdate.vue' /* webpackChunkName: "components/doc-modal-input-update" */).then(c => wrapFunctional(c.default || c)),
  FieldModalAutofield: () => import('../../components/Field/Modal/Autofield.vue' /* webpackChunkName: "components/field-modal-autofield" */).then(c => wrapFunctional(c.default || c)),
  FieldModalAutofieldCreate: () => import('../../components/Field/Modal/AutofieldCreate.vue' /* webpackChunkName: "components/field-modal-autofield-create" */).then(c => wrapFunctional(c.default || c)),
  FieldModalChangeableFieldList: () => import('../../components/Field/Modal/ChangeableFieldList.vue' /* webpackChunkName: "components/field-modal-changeable-field-list" */).then(c => wrapFunctional(c.default || c)),
  FieldModalInputCreate: () => import('../../components/Field/Modal/InputCreate.vue' /* webpackChunkName: "components/field-modal-input-create" */).then(c => wrapFunctional(c.default || c)),
  FieldModalInputUpdate: () => import('../../components/Field/Modal/InputUpdate.vue' /* webpackChunkName: "components/field-modal-input-update" */).then(c => wrapFunctional(c.default || c)),
  FieldModalInputUpdateOld: () => import('../../components/Field/Modal/InputUpdateOld.vue' /* webpackChunkName: "components/field-modal-input-update-old" */).then(c => wrapFunctional(c.default || c)),
  FieldModalTriggerList: () => import('../../components/Field/Modal/TriggerList.vue' /* webpackChunkName: "components/field-modal-trigger-list" */).then(c => wrapFunctional(c.default || c)),
  FieldModalViewCreate: () => import('../../components/Field/Modal/ViewCreate.vue' /* webpackChunkName: "components/field-modal-view-create" */).then(c => wrapFunctional(c.default || c)),
  FieldModalViewFieldsUpdate: () => import('../../components/Field/Modal/ViewFieldsUpdate.vue' /* webpackChunkName: "components/field-modal-view-fields-update" */).then(c => wrapFunctional(c.default || c)),
  FieldModalViewUpdate: () => import('../../components/Field/Modal/ViewUpdate.vue' /* webpackChunkName: "components/field-modal-view-update" */).then(c => wrapFunctional(c.default || c)),
  FieldModalInputGroupCreate: () => import('../../components/Field/Modal/inputGroupCreate.vue' /* webpackChunkName: "components/field-modal-input-group-create" */).then(c => wrapFunctional(c.default || c)),
  FieldModalInputGroupFields: () => import('../../components/Field/Modal/inputGroupFields.vue' /* webpackChunkName: "components/field-modal-input-group-fields" */).then(c => wrapFunctional(c.default || c)),
  FieldModalInputGroupUpdate: () => import('../../components/Field/Modal/inputGroupUpdate.vue' /* webpackChunkName: "components/field-modal-input-group-update" */).then(c => wrapFunctional(c.default || c)),
  FunctionModalCreate: () => import('../../components/Function/Modal/Create.vue' /* webpackChunkName: "components/function-modal-create" */).then(c => wrapFunctional(c.default || c)),
  FunctionModalUpdate: () => import('../../components/Function/Modal/Update.vue' /* webpackChunkName: "components/function-modal-update" */).then(c => wrapFunctional(c.default || c)),
  GraphModalCreateColumn: () => import('../../components/Graph/Modal/CreateColumn.vue' /* webpackChunkName: "components/graph-modal-create-column" */).then(c => wrapFunctional(c.default || c)),
  GraphModalCreateRef: () => import('../../components/Graph/Modal/CreateRef.vue' /* webpackChunkName: "components/graph-modal-create-ref" */).then(c => wrapFunctional(c.default || c)),
  GraphModalCreateTable: () => import('../../components/Graph/Modal/CreateTable.vue' /* webpackChunkName: "components/graph-modal-create-table" */).then(c => wrapFunctional(c.default || c)),
  GraphModalEditColumn: () => import('../../components/Graph/Modal/EditColumn.vue' /* webpackChunkName: "components/graph-modal-edit-column" */).then(c => wrapFunctional(c.default || c)),
  GraphModalEditTable: () => import('../../components/Graph/Modal/EditTable.vue' /* webpackChunkName: "components/graph-modal-edit-table" */).then(c => wrapFunctional(c.default || c)),
  ModuleModalCreate: () => import('../../components/Module/Modal/Create.vue' /* webpackChunkName: "components/module-modal-create" */).then(c => wrapFunctional(c.default || c)),
  ModuleModalSubmodules: () => import('../../components/Module/Modal/Submodules.vue' /* webpackChunkName: "components/module-modal-submodules" */).then(c => wrapFunctional(c.default || c)),
  ModuleModalUpdate: () => import('../../components/Module/Modal/Update.vue' /* webpackChunkName: "components/module-modal-update" */).then(c => wrapFunctional(c.default || c)),
  OperationModalCreate: () => import('../../components/Operation/Modal/Create.vue' /* webpackChunkName: "components/operation-modal-create" */).then(c => wrapFunctional(c.default || c)),
  OperationModalUpdate: () => import('../../components/Operation/Modal/Update.vue' /* webpackChunkName: "components/operation-modal-update" */).then(c => wrapFunctional(c.default || c)),
  PreoperationModalCreate: () => import('../../components/Preoperation/Modal/Create.vue' /* webpackChunkName: "components/preoperation-modal-create" */).then(c => wrapFunctional(c.default || c)),
  PreoperationModalUpdate: () => import('../../components/Preoperation/Modal/Update.vue' /* webpackChunkName: "components/preoperation-modal-update" */).then(c => wrapFunctional(c.default || c)),
  RegistryModalCreate: () => import('../../components/Registry/Modal/Create.vue' /* webpackChunkName: "components/registry-modal-create" */).then(c => wrapFunctional(c.default || c)),
  RegistryModalEdit: () => import('../../components/Registry/Modal/Edit.vue' /* webpackChunkName: "components/registry-modal-edit" */).then(c => wrapFunctional(c.default || c)),
  RelationsModalCreate: () => import('../../components/Relations/Modal/Create.vue' /* webpackChunkName: "components/relations-modal-create" */).then(c => wrapFunctional(c.default || c)),
  SubmoduleModalCreate: () => import('../../components/Submodule/Modal/Create.vue' /* webpackChunkName: "components/submodule-modal-create" */).then(c => wrapFunctional(c.default || c)),
  SubmoduleModalUpdate: () => import('../../components/Submodule/Modal/Update.vue' /* webpackChunkName: "components/submodule-modal-update" */).then(c => wrapFunctional(c.default || c)),
  UserModalCreate: () => import('../../components/User/Modal/Create.vue' /* webpackChunkName: "components/user-modal-create" */).then(c => wrapFunctional(c.default || c)),
  UserModalUpdate: () => import('../../components/User/Modal/Update.vue' /* webpackChunkName: "components/user-modal-update" */).then(c => wrapFunctional(c.default || c)),
  ClassificationAttributesModalCreate: () => import('../../components/Classification/Attributes/Modal/Create.vue' /* webpackChunkName: "components/classification-attributes-modal-create" */).then(c => wrapFunctional(c.default || c)),
  ClassificationAttributesModalUpdate: () => import('../../components/Classification/Attributes/Modal/Update.vue' /* webpackChunkName: "components/classification-attributes-modal-update" */).then(c => wrapFunctional(c.default || c)),
  DataCatalogValueCreate: () => import('../../components/Data/Catalog/Value/Create.vue' /* webpackChunkName: "components/data-catalog-value-create" */).then(c => wrapFunctional(c.default || c)),
  DataCatalogValueDelete: () => import('../../components/Data/Catalog/Value/Delete.vue' /* webpackChunkName: "components/data-catalog-value-delete" */).then(c => wrapFunctional(c.default || c)),
  DataCatalogValueUpdate: () => import('../../components/Data/Catalog/Value/Update.vue' /* webpackChunkName: "components/data-catalog-value-update" */).then(c => wrapFunctional(c.default || c)),
  DataCatalogValueUpload: () => import('../../components/Data/Catalog/Value/Upload.vue' /* webpackChunkName: "components/data-catalog-value-upload" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamDefault: () => import('../../components/Field/Modal/Param/Default.vue' /* webpackChunkName: "components/field-modal-param-default" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamFormula: () => import('../../components/Field/Modal/Param/Formula.vue' /* webpackChunkName: "components/field-modal-param-formula" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamFunction: () => import('../../components/Field/Modal/Param/Function.vue' /* webpackChunkName: "components/field-modal-param-function" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamMask: () => import('../../components/Field/Modal/Param/Mask.vue' /* webpackChunkName: "components/field-modal-param-mask" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamMaxChar: () => import('../../components/Field/Modal/Param/MaxChar.vue' /* webpackChunkName: "components/field-modal-param-max-char" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamMaxNum: () => import('../../components/Field/Modal/Param/MaxNum.vue' /* webpackChunkName: "components/field-modal-param-max-num" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamMinChar: () => import('../../components/Field/Modal/Param/MinChar.vue' /* webpackChunkName: "components/field-modal-param-min-char" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamMinNum: () => import('../../components/Field/Modal/Param/MinNum.vue' /* webpackChunkName: "components/field-modal-param-min-num" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamName: () => import('../../components/Field/Modal/Param/Name.vue' /* webpackChunkName: "components/field-modal-param-name" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamPrompt: () => import('../../components/Field/Modal/Param/Prompt.vue' /* webpackChunkName: "components/field-modal-param-prompt" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamRemember: () => import('../../components/Field/Modal/Param/Remember.vue' /* webpackChunkName: "components/field-modal-param-remember" */).then(c => wrapFunctional(c.default || c)),
  RegistryDataModalCreate: () => import('../../components/Registry/Data/Modal/Create.vue' /* webpackChunkName: "components/registry-data-modal-create" */).then(c => wrapFunctional(c.default || c)),
  RegistryDataModalEdit: () => import('../../components/Registry/Data/Modal/Edit.vue' /* webpackChunkName: "components/registry-data-modal-edit" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamCatalogCustom: () => import('../../components/Field/Modal/Param/Catalog/Custom.vue' /* webpackChunkName: "components/field-modal-param-catalog-custom" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamCatalogCustomApi: () => import('../../components/Field/Modal/Param/Catalog/CustomApi.vue' /* webpackChunkName: "components/field-modal-param-catalog-custom-api" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamCatalogCustomId: () => import('../../components/Field/Modal/Param/Catalog/CustomId.vue' /* webpackChunkName: "components/field-modal-param-catalog-custom-id" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamCatalogCustomValue: () => import('../../components/Field/Modal/Param/Catalog/CustomValue.vue' /* webpackChunkName: "components/field-modal-param-catalog-custom-value" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamCatalogCustomValueLang: () => import('../../components/Field/Modal/Param/Catalog/CustomValueLang.vue' /* webpackChunkName: "components/field-modal-param-catalog-custom-value-lang" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamCatalogSimple: () => import('../../components/Field/Modal/Param/Catalog/Simple.vue' /* webpackChunkName: "components/field-modal-param-catalog-simple" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamDateAfterCurrent: () => import('../../components/Field/Modal/Param/Date/AfterCurrent.vue' /* webpackChunkName: "components/field-modal-param-date-after-current" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamDateBeforeCurrent: () => import('../../components/Field/Modal/Param/Date/BeforeCurrent.vue' /* webpackChunkName: "components/field-modal-param-date-before-current" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamGeoColor: () => import('../../components/Field/Modal/Param/Geo/Color.vue' /* webpackChunkName: "components/field-modal-param-geo-color" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamGeoLayer: () => import('../../components/Field/Modal/Param/Geo/Layer.vue' /* webpackChunkName: "components/field-modal-param-geo-layer" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamGeoWeight: () => import('../../components/Field/Modal/Param/Geo/Weight.vue' /* webpackChunkName: "components/field-modal-param-geo-weight" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamGeoCatalogLayer: () => import('../../components/Field/Modal/Param/GeoCatalog/Layer.vue' /* webpackChunkName: "components/field-modal-param-geo-catalog-layer" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamGeoCatalogLayerColumn: () => import('../../components/Field/Modal/Param/GeoCatalog/LayerColumn.vue' /* webpackChunkName: "components/field-modal-param-geo-catalog-layer-column" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamRegistryCatalog: () => import('../../components/Field/Modal/Param/Registry/Catalog.vue' /* webpackChunkName: "components/field-modal-param-registry-catalog" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamRegistryLangValues: () => import('../../components/Field/Modal/Param/Registry/LangValues.vue' /* webpackChunkName: "components/field-modal-param-registry-lang-values" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamStaticBold: () => import('../../components/Field/Modal/Param/Static/Bold.vue' /* webpackChunkName: "components/field-modal-param-static-bold" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamStaticFontSize: () => import('../../components/Field/Modal/Param/Static/FontSize.vue' /* webpackChunkName: "components/field-modal-param-static-font-size" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamStaticItalic: () => import('../../components/Field/Modal/Param/Static/Italic.vue' /* webpackChunkName: "components/field-modal-param-static-italic" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableColumnRegistryCatalog: () => import('../../components/Field/Modal/Param/Table/ColumnRegistryCatalog.vue' /* webpackChunkName: "components/field-modal-param-table-column-registry-catalog" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableColumns: () => import('../../components/Field/Modal/Param/Table/Columns.vue' /* webpackChunkName: "components/field-modal-param-table-columns" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableDark: () => import('../../components/Field/Modal/Param/Table/Dark.vue' /* webpackChunkName: "components/field-modal-param-table-dark" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableEditable: () => import('../../components/Field/Modal/Param/Table/Editable.vue' /* webpackChunkName: "components/field-modal-param-table-editable" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableFixed: () => import('../../components/Field/Modal/Param/Table/Fixed.vue' /* webpackChunkName: "components/field-modal-param-table-fixed" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableFootClone: () => import('../../components/Field/Modal/Param/Table/FootClone.vue' /* webpackChunkName: "components/field-modal-param-table-foot-clone" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableFunction: () => import('../../components/Field/Modal/Param/Table/Function.vue' /* webpackChunkName: "components/field-modal-param-table-function" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableHeadVariant: () => import('../../components/Field/Modal/Param/Table/HeadVariant.vue' /* webpackChunkName: "components/field-modal-param-table-head-variant" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableHover: () => import('../../components/Field/Modal/Param/Table/Hover.vue' /* webpackChunkName: "components/field-modal-param-table-hover" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableInnerBorder: () => import('../../components/Field/Modal/Param/Table/InnerBorder.vue' /* webpackChunkName: "components/field-modal-param-table-inner-border" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableNoBorderCollapse: () => import('../../components/Field/Modal/Param/Table/NoBorderCollapse.vue' /* webpackChunkName: "components/field-modal-param-table-no-border-collapse" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableOuterBorder: () => import('../../components/Field/Modal/Param/Table/OuterBorder.vue' /* webpackChunkName: "components/field-modal-param-table-outer-border" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableOutlined: () => import('../../components/Field/Modal/Param/Table/Outlined.vue' /* webpackChunkName: "components/field-modal-param-table-outlined" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTablePrompt: () => import('../../components/Field/Modal/Param/Table/Prompt.vue' /* webpackChunkName: "components/field-modal-param-table-prompt" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableSmall: () => import('../../components/Field/Modal/Param/Table/Small.vue' /* webpackChunkName: "components/field-modal-param-table-small" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableStriped: () => import('../../components/Field/Modal/Param/Table/Striped.vue' /* webpackChunkName: "components/field-modal-param-table-striped" */).then(c => wrapFunctional(c.default || c)),
  FieldModalParamTableVariant: () => import('../../components/Field/Modal/Param/Table/Variant.vue' /* webpackChunkName: "components/field-modal-param-table-variant" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
