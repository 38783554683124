import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6640797a = () => interopDefault(import('../pages/classification.vue' /* webpackChunkName: "pages/classification" */))
const _10d4a08a = () => interopDefault(import('../pages/classification/get/_classificationId.vue' /* webpackChunkName: "pages/classification/get/_classificationId" */))
const _92564d4a = () => interopDefault(import('../pages/classification/get/_classificationId/attributes.vue' /* webpackChunkName: "pages/classification/get/_classificationId/attributes" */))
const _3c49f9a0 = () => interopDefault(import('../pages/classification/get/_classificationId/attributes/customizable.vue' /* webpackChunkName: "pages/classification/get/_classificationId/attributes/customizable" */))
const _01c0d3a6 = () => interopDefault(import('../pages/classification/get/_classificationId/attributes/inherited.vue' /* webpackChunkName: "pages/classification/get/_classificationId/attributes/inherited" */))
const _402b3232 = () => interopDefault(import('../pages/classification/get/_classificationId/info.vue' /* webpackChunkName: "pages/classification/get/_classificationId/info" */))
const _d7a80172 = () => interopDefault(import('../pages/data.vue' /* webpackChunkName: "pages/data" */))
const _51fa052e = () => interopDefault(import('../pages/data/catalogs/create.vue' /* webpackChunkName: "pages/data/catalogs/create" */))
const _0c155d4b = () => interopDefault(import('../pages/data/catalogs/list.vue' /* webpackChunkName: "pages/data/catalogs/list" */))
const _107eb9ea = () => interopDefault(import('../pages/data/locales/create.vue' /* webpackChunkName: "pages/data/locales/create" */))
const _c2af73e8 = () => interopDefault(import('../pages/data/locales/list.vue' /* webpackChunkName: "pages/data/locales/list" */))
const _16743343 = () => interopDefault(import('../pages/data/organizations/create.vue' /* webpackChunkName: "pages/data/organizations/create" */))
const _6e2e41a5 = () => interopDefault(import('../pages/data/organizations/list.vue' /* webpackChunkName: "pages/data/organizations/list" */))
const _05cffbab = () => interopDefault(import('../pages/data/territories/create.vue' /* webpackChunkName: "pages/data/territories/create" */))
const _d99df7e6 = () => interopDefault(import('../pages/data/territories/list.vue' /* webpackChunkName: "pages/data/territories/list" */))
const _08a4e961 = () => interopDefault(import('../pages/data/catalogs/update/_id.vue' /* webpackChunkName: "pages/data/catalogs/update/_id" */))
const _f527e176 = () => interopDefault(import('../pages/data/catalogs/values/_catalogId.vue' /* webpackChunkName: "pages/data/catalogs/values/_catalogId" */))
const _6cfc7d62 = () => interopDefault(import('../pages/data/locales/update/_id.vue' /* webpackChunkName: "pages/data/locales/update/_id" */))
const _1e73a23b = () => interopDefault(import('../pages/data/organizations/update/_id.vue' /* webpackChunkName: "pages/data/organizations/update/_id" */))
const _0d6478ba = () => interopDefault(import('../pages/data/services/create/_parentId.vue' /* webpackChunkName: "pages/data/services/create/_parentId" */))
const _7a20c5c8 = () => interopDefault(import('../pages/data/services/list/_parentId.vue' /* webpackChunkName: "pages/data/services/list/_parentId" */))
const _6bc097fd = () => interopDefault(import('../pages/data/services/update/_id.vue' /* webpackChunkName: "pages/data/services/update/_id" */))
const _1f3612ba = () => interopDefault(import('../pages/data/territories/update/_id.vue' /* webpackChunkName: "pages/data/territories/update/_id" */))
const _c0dfa678 = () => interopDefault(import('../pages/deploy.vue' /* webpackChunkName: "pages/deploy" */))
const _7d28b64e = () => interopDefault(import('../pages/formula.vue' /* webpackChunkName: "pages/formula" */))
const _e28f4680 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _227c5343 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _7229faae = () => interopDefault(import('../pages/settings/activity.vue' /* webpackChunkName: "pages/settings/activity" */))
const _2325b746 = () => interopDefault(import('../pages/settings/dev.vue' /* webpackChunkName: "pages/settings/dev" */))
const _dafd9d6e = () => interopDefault(import('../pages/settings/dev/index.vue' /* webpackChunkName: "pages/settings/dev/index" */))
const _f174d810 = () => interopDefault(import('../pages/settings/dev/core.vue' /* webpackChunkName: "pages/settings/dev/core" */))
const _d4b407dc = () => interopDefault(import('../pages/settings/dev/functions.vue' /* webpackChunkName: "pages/settings/dev/functions" */))
const _5fdf1005 = () => interopDefault(import('../pages/settings/dev/module.vue' /* webpackChunkName: "pages/settings/dev/module" */))
const _1fae03de = () => interopDefault(import('../pages/settings/dev/operation.vue' /* webpackChunkName: "pages/settings/dev/operation" */))
const _5d76e2dd = () => interopDefault(import('../pages/settings/dev/preOperation.vue' /* webpackChunkName: "pages/settings/dev/preOperation" */))
const _e2248eba = () => interopDefault(import('../pages/settings/dev/submodule.vue' /* webpackChunkName: "pages/settings/dev/submodule" */))
const _67e62d79 = () => interopDefault(import('../pages/settings/users.vue' /* webpackChunkName: "pages/settings/users" */))
const _7523601f = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _93fa591e = () => interopDefault(import('../pages/project/list.vue' /* webpackChunkName: "pages/project/list" */))
const _0dec4a01 = () => interopDefault(import('../pages/project/get/_projectId.vue' /* webpackChunkName: "pages/project/get/_projectId" */))
const _424dcc36 = () => interopDefault(import('../pages/project/get/_projectId/deploy.vue' /* webpackChunkName: "pages/project/get/_projectId/deploy" */))
const _1d4fd8a8 = () => interopDefault(import('../pages/project/get/_projectId/info.vue' /* webpackChunkName: "pages/project/get/_projectId/info" */))
const _697bd8c0 = () => interopDefault(import('../pages/project/get/_projectId/layers.vue' /* webpackChunkName: "pages/project/get/_projectId/layers" */))
const _8c7f8d0e = () => interopDefault(import('../pages/project/get/_projectId/modules.vue' /* webpackChunkName: "pages/project/get/_projectId/modules" */))
const _28be535b = () => interopDefault(import('../pages/project/get/_projectId/orgByTerritories.vue' /* webpackChunkName: "pages/project/get/_projectId/orgByTerritories" */))
const _6d810e7d = () => interopDefault(import('../pages/project/get/_projectId/ts.vue' /* webpackChunkName: "pages/project/get/_projectId/ts" */))
const _92d30dbc = () => interopDefault(import('../pages/project/get/_projectId/registry/list.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/list" */))
const _6ff3a1ca = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId" */))
const _97d75028 = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId/attributes.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId/attributes" */))
const _bc77de12 = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId/attributes/inherited.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId/attributes/inherited" */))
const _5aa34763 = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId/attributes/own.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId/attributes/own" */))
const _2f564302 = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId/data.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId/data" */))
const _ef659cfa = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId/info.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId/info" */))
const _783141f2 = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId/relations.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId/relations" */))
const _7ba0ab7d = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId/relations/inherited.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId/relations/inherited" */))
const _dae0832e = () => interopDefault(import('../pages/project/get/_projectId/registry/get/_registryId/relations/own.vue' /* webpackChunkName: "pages/project/get/_projectId/registry/get/_registryId/relations/own" */))
const _08bf3c38 = () => interopDefault(import('../pages/project/get/_projectId/deploy-info/_deployId.vue' /* webpackChunkName: "pages/project/get/_projectId/deploy-info/_deployId" */))
const _248b1254 = () => interopDefault(import('../pages/project/get/_projectId/deploy-info/_deployId/progress.vue' /* webpackChunkName: "pages/project/get/_projectId/deploy-info/_deployId/progress" */))
const _652a6a40 = () => interopDefault(import('../pages/project/get/_projectId/deploy-info/_deployId/validation.vue' /* webpackChunkName: "pages/project/get/_projectId/deploy-info/_deployId/validation" */))
const _49e88bc3 = () => interopDefault(import('../pages/ts/_projectId/create.vue' /* webpackChunkName: "pages/ts/_projectId/create" */))
const _7123981a = () => interopDefault(import('../pages/ts/_projectId/update/_tsId.vue' /* webpackChunkName: "pages/ts/_projectId/update/_tsId" */))
const _1e8972aa = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get" */))
const _d3763e24 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/index.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/index" */))
const _02181ed4 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node" */))
const _1ce15ce3 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId" */))
const _f27eb4e8 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc" */))
const _46b459e2 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc/index.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc/index" */))
const _7285c607 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc/input.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc/input" */))
const _1b299090 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc/sign.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc/sign" */))
const _4fe23978 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc/view.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/doc/view" */))
const _64ea914e = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field" */))
const _26ec6551 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field/index.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field/index" */))
const _58ed8b19 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field/event.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field/event" */))
const _86674f6e = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field/input.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field/input" */))
const _313f56f6 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field/view.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/field/view" */))
const _2c657e0a = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/info.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/node/_nodeId/info" */))
const _343868a6 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/passport.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/passport" */))
const _5bc5b5b4 = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/passport/editor.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/passport/editor" */))
const _2587305e = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/passport/editorOld.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/passport/editorOld" */))
const _625f96df = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/passport/preview.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/passport/preview" */))
const _468029da = () => interopDefault(import('../pages/tsRoute/_projectId/_tsId/_tsRouteId/get/remote.vue' /* webpackChunkName: "pages/tsRoute/_projectId/_tsId/_tsRouteId/get/remote" */))
const _4d09bdf6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/classification",
    component: _6640797a,
    name: "classification",
    children: [{
      path: "get/:classificationId?",
      component: _10d4a08a,
      name: "classification-get-classificationId",
      children: [{
        path: "attributes",
        component: _92564d4a,
        name: "classification-get-classificationId-attributes",
        children: [{
          path: "customizable",
          component: _3c49f9a0,
          name: "classification-get-classificationId-attributes-customizable"
        }, {
          path: "inherited",
          component: _01c0d3a6,
          name: "classification-get-classificationId-attributes-inherited"
        }]
      }, {
        path: "info",
        component: _402b3232,
        name: "classification-get-classificationId-info"
      }]
    }]
  }, {
    path: "/data",
    component: _d7a80172,
    name: "data",
    children: [{
      path: "catalogs/create",
      component: _51fa052e,
      name: "data-catalogs-create"
    }, {
      path: "catalogs/list",
      component: _0c155d4b,
      name: "data-catalogs-list"
    }, {
      path: "locales/create",
      component: _107eb9ea,
      name: "data-locales-create"
    }, {
      path: "locales/list",
      component: _c2af73e8,
      name: "data-locales-list"
    }, {
      path: "organizations/create",
      component: _16743343,
      name: "data-organizations-create"
    }, {
      path: "organizations/list",
      component: _6e2e41a5,
      name: "data-organizations-list"
    }, {
      path: "territories/create",
      component: _05cffbab,
      name: "data-territories-create"
    }, {
      path: "territories/list",
      component: _d99df7e6,
      name: "data-territories-list"
    }, {
      path: "catalogs/update/:id?",
      component: _08a4e961,
      name: "data-catalogs-update-id"
    }, {
      path: "catalogs/values/:catalogId?",
      component: _f527e176,
      name: "data-catalogs-values-catalogId"
    }, {
      path: "locales/update/:id?",
      component: _6cfc7d62,
      name: "data-locales-update-id"
    }, {
      path: "organizations/update/:id?",
      component: _1e73a23b,
      name: "data-organizations-update-id"
    }, {
      path: "services/create/:parentId?",
      component: _0d6478ba,
      name: "data-services-create-parentId"
    }, {
      path: "services/list/:parentId?",
      component: _7a20c5c8,
      name: "data-services-list-parentId"
    }, {
      path: "services/update/:id?",
      component: _6bc097fd,
      name: "data-services-update-id"
    }, {
      path: "territories/update/:id?",
      component: _1f3612ba,
      name: "data-territories-update-id"
    }]
  }, {
    path: "/deploy",
    component: _c0dfa678,
    name: "deploy"
  }, {
    path: "/formula",
    component: _7d28b64e,
    name: "formula"
  }, {
    path: "/settings",
    component: _e28f4680,
    children: [{
      path: "",
      component: _227c5343,
      name: "settings"
    }, {
      path: "activity",
      component: _7229faae,
      name: "settings-activity"
    }, {
      path: "dev",
      component: _2325b746,
      children: [{
        path: "",
        component: _dafd9d6e,
        name: "settings-dev"
      }, {
        path: "core",
        component: _f174d810,
        name: "settings-dev-core"
      }, {
        path: "functions",
        component: _d4b407dc,
        name: "settings-dev-functions"
      }, {
        path: "module",
        component: _5fdf1005,
        name: "settings-dev-module"
      }, {
        path: "operation",
        component: _1fae03de,
        name: "settings-dev-operation"
      }, {
        path: "preOperation",
        component: _5d76e2dd,
        name: "settings-dev-preOperation"
      }, {
        path: "submodule",
        component: _e2248eba,
        name: "settings-dev-submodule"
      }]
    }, {
      path: "users",
      component: _67e62d79,
      name: "settings-users"
    }]
  }, {
    path: "/auth/login",
    component: _7523601f,
    name: "auth-login"
  }, {
    path: "/project/list",
    component: _93fa591e,
    name: "project-list"
  }, {
    path: "/project/get/:projectId?",
    component: _0dec4a01,
    name: "project-get-projectId",
    children: [{
      path: "deploy",
      component: _424dcc36,
      name: "project-get-projectId-deploy"
    }, {
      path: "info",
      component: _1d4fd8a8,
      name: "project-get-projectId-info"
    }, {
      path: "layers",
      component: _697bd8c0,
      name: "project-get-projectId-layers"
    }, {
      path: "modules",
      component: _8c7f8d0e,
      name: "project-get-projectId-modules"
    }, {
      path: "orgByTerritories",
      component: _28be535b,
      name: "project-get-projectId-orgByTerritories"
    }, {
      path: "ts",
      component: _6d810e7d,
      name: "project-get-projectId-ts"
    }, {
      path: "registry/list",
      component: _92d30dbc,
      name: "project-get-projectId-registry-list"
    }, {
      path: "registry/get/:registryId?",
      component: _6ff3a1ca,
      name: "project-get-projectId-registry-get-registryId",
      children: [{
        path: "attributes",
        component: _97d75028,
        name: "project-get-projectId-registry-get-registryId-attributes",
        children: [{
          path: "inherited",
          component: _bc77de12,
          name: "project-get-projectId-registry-get-registryId-attributes-inherited"
        }, {
          path: "own",
          component: _5aa34763,
          name: "project-get-projectId-registry-get-registryId-attributes-own"
        }]
      }, {
        path: "data",
        component: _2f564302,
        name: "project-get-projectId-registry-get-registryId-data"
      }, {
        path: "info",
        component: _ef659cfa,
        name: "project-get-projectId-registry-get-registryId-info"
      }, {
        path: "relations",
        component: _783141f2,
        name: "project-get-projectId-registry-get-registryId-relations",
        children: [{
          path: "inherited",
          component: _7ba0ab7d,
          name: "project-get-projectId-registry-get-registryId-relations-inherited"
        }, {
          path: "own",
          component: _dae0832e,
          name: "project-get-projectId-registry-get-registryId-relations-own"
        }]
      }]
    }, {
      path: "deploy-info/:deployId?",
      component: _08bf3c38,
      name: "project-get-projectId-deploy-info-deployId",
      children: [{
        path: "progress",
        component: _248b1254,
        name: "project-get-projectId-deploy-info-deployId-progress"
      }, {
        path: "validation",
        component: _652a6a40,
        name: "project-get-projectId-deploy-info-deployId-validation"
      }]
    }]
  }, {
    path: "/ts/:projectId?/create",
    component: _49e88bc3,
    name: "ts-projectId-create"
  }, {
    path: "/ts/:projectId?/update/:tsId?",
    component: _7123981a,
    name: "ts-projectId-update-tsId"
  }, {
    path: "/tsRoute/:projectId?/:tsId?/:tsRouteId?/get",
    component: _1e8972aa,
    children: [{
      path: "",
      component: _d3763e24,
      name: "tsRoute-projectId-tsId-tsRouteId-get"
    }, {
      path: "node",
      component: _02181ed4,
      name: "tsRoute-projectId-tsId-tsRouteId-get-node",
      children: [{
        path: ":nodeId?",
        component: _1ce15ce3,
        name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId",
        children: [{
          path: "doc",
          component: _f27eb4e8,
          children: [{
            path: "",
            component: _46b459e2,
            name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-doc"
          }, {
            path: "input",
            component: _7285c607,
            name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-doc-input"
          }, {
            path: "sign",
            component: _1b299090,
            name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-doc-sign"
          }, {
            path: "view",
            component: _4fe23978,
            name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-doc-view"
          }]
        }, {
          path: "field",
          component: _64ea914e,
          children: [{
            path: "",
            component: _26ec6551,
            name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-field"
          }, {
            path: "event",
            component: _58ed8b19,
            name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-field-event"
          }, {
            path: "input",
            component: _86674f6e,
            name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-field-input"
          }, {
            path: "view",
            component: _313f56f6,
            name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-field-view"
          }]
        }, {
          path: "info",
          component: _2c657e0a,
          name: "tsRoute-projectId-tsId-tsRouteId-get-node-nodeId-info"
        }]
      }]
    }, {
      path: "passport",
      component: _343868a6,
      name: "tsRoute-projectId-tsId-tsRouteId-get-passport",
      children: [{
        path: "editor",
        component: _5bc5b5b4,
        name: "tsRoute-projectId-tsId-tsRouteId-get-passport-editor"
      }, {
        path: "editorOld",
        component: _2587305e,
        name: "tsRoute-projectId-tsId-tsRouteId-get-passport-editorOld"
      }, {
        path: "preview",
        component: _625f96df,
        name: "tsRoute-projectId-tsId-tsRouteId-get-passport-preview"
      }]
    }, {
      path: "remote",
      component: _468029da,
      name: "tsRoute-projectId-tsId-tsRouteId-get-remote"
    }]
  }, {
    path: "/",
    component: _4d09bdf6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
