import {
  TOOLBAR_EDIT_TABLE_HANDLER,
  TOOLBAR_ADD_COLUMN_HANDLER,
  TOOLBAR_CREATE_REF_HANDLER,
  TOOLBAR_REMOVE_TABLE_HANDLER,
  COLUMN_EDIT_HANDLER,
  COLUMN_REMOVE_HANDLER,
} from '../action-types';

export const toolbarButtons = (dispatch) => [
  {
    id: "edit",
    condition: () => true,
    handler: (classBox) => dispatch(TOOLBAR_EDIT_TABLE_HANDLER, { classBox }),
    tooltip: "Редактировать таблицу",
    content: function (symbol) {
      symbol
        .append("path")
        .attr(
          "d",
          "M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
        );
    },
  },
  {
    id: "add",
    condition: () => true,
    handler: (classBox) => dispatch(TOOLBAR_ADD_COLUMN_HANDLER, { classBox }),
    tooltip: "Добавить столбец",
    content: function (symbol) {
      symbol
        .append("path")
        .attr(
          "d",
          "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
        );
    },
  },
  // {
  //   id: "restore",
  //   condition: () => false,
  //   tooltip: "Восстановить таблицу",
  //   content: function (symbol) {
  //     symbol
  //       .append("path")
  //       .attr(
  //         "d",
  //         "M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1Z"
  //       );
  //     symbol
  //       .append("path")
  //       .attr(
  //         "d",
  //         "M3.904 9.223C2.875 8.755 2 8.007 2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-1.364-.125 2.988 2.988 0 0 0-2.197.731 4.525 4.525 0 0 0-1.254 1.237A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777ZM8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.09 0 .178 0 .266-.003A1.99 1.99 0 0 1 8 15v-1Zm0-1.5c0 .1.003.201.01.3A1.9 1.9 0 0 0 8 13c-1.573 0-3.022-.289-4.096-.777C2.875 11.755 2 11.007 2 10v-.839c.457.432 1.004.751 1.49.972C4.722 10.693 6.318 11 8 11c.086 0 .172 0 .257-.002A4.5 4.5 0 0 0 8 12.5Z"
  //       );
  //     symbol
  //       .append("path")
  //       .attr(
  //         "d",
  //         "M9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z"
  //       );
  //   },
  // },
  {
    id: "remove",
    condition: () => true,
    handler: (classBox) => dispatch(TOOLBAR_REMOVE_TABLE_HANDLER, { classBox }),
    tooltip: "Удалить таблицу",
    content: function (symbol) {
      symbol
        .append("path")
        .attr(
          "d",
          "M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"
        );
      symbol
        .append("path")
        .attr(
          "d",
          "M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"
        );
    },
  },
  {
    id: "link",
    condition: () => true,
    handler: (classBox) => dispatch(TOOLBAR_CREATE_REF_HANDLER, { classBox }),
    tooltip: "Связать таблицу",
    content: function (symbol) {
      symbol
        .append("path")
        .attr("fill-rule", "evenodd")
        .attr(
          "d",
          "M14 13.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h4.793L2.146 2.854a.5.5 0 1 1 .708-.708L13 12.293V7.5a.5.5 0 0 1 1 0v6z"
        );
    },
  },
];

export const columnButtons = (dispatch) => [
  {
    id: "removeColumn",
    condition: (attrBox) => !attrBox.data().is_pk,
    handler: (attributeBox) => dispatch(COLUMN_REMOVE_HANDLER, { attributeBox }),
    tooltip: "Удалить столбец",
    content: function (symbol) {
      symbol
        .append("path")
        .attr(
          "d",
          "M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"
        );
      symbol
        .append("path")
        .attr(
          "d",
          "M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"
        );
    },
  },
  {
    id: "editColumn",
    condition: () => true,
    handler: (attributeBox) => dispatch(COLUMN_EDIT_HANDLER, { attributeBox }),
    tooltip: "Редактировать столбец",
    content: function (symbol) {
      symbol
        .append("path")
        .attr(
          "d",
          "M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
        );
    },
  },
];
