import Vue from 'vue';
import {
  SET_PROJECT_INFO_LOADING,
  SET_PROJECT_ID,
  SET_CURRENT_PROJECT,
} from './mutation-types'

export default {
  [SET_PROJECT_INFO_LOADING](state, flag) {
    state.projectInfoLoading = flag;
  },
  [SET_PROJECT_ID](state, projectId) {
    state.projectId = projectId;
  },
  [SET_CURRENT_PROJECT](state, project) {
    state.currentProject = project;
  },
}
