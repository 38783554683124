export const SET_TABLES_LOADING = 'SET_TABLES_LOADING';
export const SET_TABLES = 'SET_TABLES';
export const SET_STRUCTURE = 'SET_STRUCTURE';
export const SET_VIEWS_LOADING = 'SET_VIEWS_LOADING';
export const SET_VIEWS = 'SET_VIEWS';
export const SET_GRAPH = 'SET_GRAPH';
export const SET_CURRENT_TABLE = 'SET_CURRENT_TABLE';
export const SET_CURRENT_COLUMN = 'SET_CURRENT_COLUMN';
export const SET_REF_MODE = 'SET_REF_MODE';
export const SET_REF_TABLE = 'SET_REF_TABLE';
export const SET_SEARCH_GENERATOR = 'SET_SEARCH_GENERATOR';

export const SET_MODAL_CREATE_TABLE = 'SET_MODAL_CREATE_TABLE';
export const SET_MODAL_EDIT_TABLE = 'SET_MODAL_EDIT_TABLE';
export const SET_MODAL_ADD_COLUMN = 'SET_MODAL_ADD_COLUMN';
export const SET_MODAL_ADD_REF = 'SET_MODAL_ADD_REF';
export const SET_MODAL_EDIT_COLUMN = 'SET_MODAL_EDIT_COLUMN';
