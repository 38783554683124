import * as D3 from 'd3';
import _ from 'lodash';

import { ClassBox, AttributeBox } from './classBox';
import { setAttr } from './utils';


export default class ClassDiagram {
  #wrapper;
  #svg;
  #zoom;
  #g;
  #classes;
  #refs;
  #width;
  #height;
  #toolbarButtons;
  #columnButtons = [];
  #boxes;
  #connectors;
  #posOpts;
  #activeBox;
  #mode;
  #modeDisabler;
  #tempConnector;
  #currentTransform;

  MODES = {
    SELECT: 'SELECT',
    LINK: 'LINK',
  }

  constructor({
    classes,
    refs,
    wrapper,
    toolbarButtons,
    columnButtons,
    width,
    height,
    posOpts
  } = {}) {
    if (classes) this.classes(classes);
    if (refs) this.refs(refs);
    if (wrapper) this.wrapper(wrapper);
    if (toolbarButtons) this.toolbarButtons(toolbarButtons);
    if (columnButtons) this.columnButtons(columnButtons)
    if (width) this.width(width);
    if (height) this.height(height);
    if (posOpts) this.posOpts(posOpts);

    this.#zoom = D3.zoom().on("zoom", this.handleZoom);

    window.addEventListener("resize", this.resizeHandler);
  }

  destroy() {
    this.#svg.remove();
    window.removeEventListener('resize', this.resizeHandler);
  }

  draw() {
    if (!this.#wrapper) throw new Error('wrapper is not defined');
    if (!this.#toolbarButtons) throw new Error('toolbarButtons is not defined');

    this.#modeDisabler?.();
    this.#svg?.remove();

    this.#svg = D3.select(this.#wrapper)
      .append("svg")
      .attr("width", this.#width)
      .attr("height", this.#height)
      .call(this.#zoom);

    this.#g = this.#svg.append("g");

    if (this.#currentTransform) {
      this.#zoom.transform(this.#svg, this.#currentTransform);
    }

    this.addMarkers(this.#g.append("defs"));
    this.addSymbols();
    this.createClassBoxes();
    this.autoPositioning();

    this.setMode(this.MODES.SELECT);
  }

  resizeHandler = () => {
    if (!this.#svg) return;

    this.#width = this.#wrapper.offsetWidth;
    this.#height = this.#wrapper.offsetHeight;

    this.#svg
      .attr("width", this.#wrapper.offsetWidth)
      .attr("height", this.#wrapper.offsetHeight);
  }

  wrapper(wrp) {
    if (!wrp) return this.#wrapper;

    this.#wrapper = wrp;

    this.#width = wrp.offsetWidth;
    this.#height = wrp.offsetHeight;

    return this;
  }

  classes(cls) {
    if (!cls) return this.#classes;

    this.#classes = cls;

    return this;
  }

  refs(refs) {
    if (!refs) return this.#refs;

    this.#refs = refs;

    return this;
  }

  toolbarButtons(btns) {
    if (!btns) return this.#toolbarButtons;

    this.#toolbarButtons = btns;

    return this;
  }

  columnButtons(btns) {
    if (!btns) return this.#columnButtons;

    this.#columnButtons = btns;

    return this;
  }

  width(width) {
    if (!width) return this.#width;

    this.#width = width;

    if (this.#svg) {
      this.svg.attr("width", width);
    }

    return this;
  }

  height(height) {
    if (!height) return this.#height;

    this.#height = height;

    if (this.#svg) {
      this.svg.attr("height", height);
    }

    return this;
  }

  boxes(boxes) {
    if (!boxes) return this.#boxes;

    this.#boxes = boxes;

    return this;
  }

  connectors(connectors) {
    if (!connectors) return this.#connectors;

    this.#connectors = connectors;

    return this;
  }

  posOpts(posOpts) {
    if (!posOpts) return this.#posOpts;

    this.#posOpts = posOpts;

    return this;
  }

  handleZoom = (e) => {
    if (e?.sourceEvent?.ctrlKey) return;

    this.#currentTransform = e.transform;
    this.#g.attr("transform", e.transform);
  }

  zoomToBox = (classBox) => {
    const x = classBox?.midX();
    const y = classBox?.midY();

    this.#zoom.translateTo(this.#svg, x, y);
    this.#zoom.scaleTo(this.#svg, 1.5);
  }

  *searchGenerator(text) {
    let notFound = true;

    do {
      for (let boxName in this.#boxes) {
        if (!boxName.includes(text)) continue;

        notFound = false;

        yield this.#boxes[boxName];
      }

      for (let box of _.values(this.#boxes)) {
        for (let attr of _.values(box.attributes())) {
          if (!attr.data()?.name.includes(text)) continue;

          notFound = false;

          yield attr;
        }
      }
    } while (!notFound);
  }

  addSymbols() {
    this.#g
      .selectAll("symbol.btn-ico")
      .data([
        ...this.#toolbarButtons,
        ...this.#columnButtons
      ])
      .enter()
      .append("symbol")
      .attr("id", (d) => d.id)
      .attr("width", 20)
      .attr("height", 20)
      .attr("viewBox", "0 0 16 16")
      .each(function (d) {
        if (d.content && d.content.call) {
          d.content(D3.select(this));
        }
      });
  }

  addMarkers(defs) {
    defs
      .append("marker")
      .attr("id", "arrowhead")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 10)
      .attr("refY", 5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M10 5 0 10 0 8.7 6.8 5.5 0 5.5 0 4.5 6.8 4.5 0 1.3 0 0Z")
      .attr("stroke", "none")
      .attr("fill", "black")
      .attr("markerUnits", "none");
    defs
      .append("marker")
      .attr("id", "arrowhead-hover")
      .attr("viewBox", "0 0 20 20")
      .attr("refX", 10)
      .attr("refY", 5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M10 5 0 10 0 8.7 6.8 5.5 0 5.5 0 4.5 6.8 4.5 0 1.3 0 0Z")
      .attr("stroke", "#6263d5")
      .attr("fill", "#6263d5")
      .attr("markerUnits", "none");
  }

  createClassBoxes() {
    const boxes = _.chain(this.#classes)
      .keyBy('name')
      .mapValues(c => {
        return new ClassBox(c, this.#g, { width: 300})
          .buttons(this.#toolbarButtons)
          .attributeButtons(this.#columnButtons)
      })
      .value();

    this.boxes(boxes);
  }

  createConnectors(connectors, classname = 'connector') {
    var line = D3
      .line()
      .x(function (d) {
        return d.x;
      })
      .y(function (d) {
        return d.y;
      });

    const connectorsPath = this.#g
      .selectAll(`path.${classname}`)
      .data(connectors)
      .enter()
      .append("path")
      .attr("opacity", 1)
      .attr("data-from", (d) => d.from)
      .attr("data-to", (d) => d.to)
      .each(function (d, i) {
        var path = D3.select(this);
        setAttr(path, {
          class: classname,
          d: line(d.points),
          stroke: "black",
          "stroke-width": 1,
          fill: "none"
        });
        path.attr("marker-end", "url(#arrowhead)");
      });

    let svg = this.#g;

    this.#g
      .selectAll(`path.${classname}`)
      .attr("stroke-dasharray", function () {
        var path = D3.select(this),
          totalLength = path.node().getTotalLength(),
          marker = svg.select("#arrowhead").node(),
          markerWidth = marker.markerWidth.baseVal.value;
        return `${totalLength - markerWidth} ${markerWidth}`;
      })
      .attr("stroke-dashoffset", 0);

    return connectorsPath;
  }

  autoPositioning() {
    const {
      width = this.#width,
      gap = 100,
      padding = 30,
      paddingX = 30,
      paddingY = 50
    } = this.#posOpts || {};

    let currRowY = paddingY || padding,
      nextRowY = 0,
      currBoxX = paddingX,
      registries = Object.values(this.#boxes);

    registries.forEach((reg) => {
      if (currBoxX + reg.width() > width) {
        currRowY = nextRowY;
        currBoxX = paddingX || padding;
      }

      reg.x(currBoxX);
      reg.y(currRowY);

      let newNextRowY = currRowY + reg.height() + gap;
      nextRowY = _.max([newNextRowY, nextRowY]);

      currBoxX += reg.width() + gap;

      reg.updateProps();
    });

    let connectors = this.#refs.map((ref) => {
      const chain = _.chain(this.#boxes);
      const [src, srcBox] = chain
        .entries()
        .find(([__, b]) => b.data()._id === ref.ref_table_id)
        .value();
      const [trgt, trgtBox] = chain
        .entries()
        .find(([__, b]) => b.data()._id === ref.table_id)
        .value();

      const points = this.getConnectorPoints(
        srcBox.attributes()[ref.ref_column_id],
        trgtBox.attributes()[ref.column_id],
        gap
      );

      let sAttr = srcBox.selection().attr("data-linked"),
        sLinks = new Set(sAttr ? sAttr.split(",") : []),
        tAttr = trgtBox.selection().attr("data-linked"),
        tLinks = new Set(tAttr ? tAttr.split(",") : []);

      sLinks.add(`${trgtBox.classname()}Class`);
      tLinks.add(`${srcBox.classname()}Class`);
      srcBox.selection().attr("data-linked", [...sLinks]);
      trgtBox.selection().attr("data-linked", [...tLinks]);

      return {
        from: `${srcBox.classname()}Class`,
        to: `${trgtBox.classname()}Class`,
        points
      };
    });

    this.#connectors = this.createConnectors(connectors);

    this.#g.selectAll("g.class").each(function() {
      this.parentNode.appendChild(this);
    });
  }

  getConnectorPoints(source, target, gap=100) {
    if (!source || !target) return false;

    const sourceX = source.x(),
      sourceRightX = source.rightX(),
      sourceY = source.midY(),
      targetX = target.x(),
      targetRightX = target.rightX(),
      targetY = target.midY(),
      diffX = Math.abs(source.x() - target.x()),
      diffRightX = Math.abs(source.rightX() - target.rightX());

    const curveMidPoint = _.max([
      _.max([
        target instanceof ClassBox ? target.y(): target.class().y(),
        source instanceof ClassBox ? source.y(): source.class().y(),
      ]) - gap/2,
      _.min([sourceY, targetY])
    ])

    switch (true) {
      case source.x() > target.rightX():
        return [
          { x: sourceX, y: sourceY },
          { x: sourceX - 20, y: sourceY },
          { x: sourceX - 20, y: curveMidPoint },
          { x: targetRightX + 20, y: curveMidPoint },
          { x: targetRightX + 20, y: targetY },
          { x: targetRightX, y: targetY }
        ];
      case source.rightX() <= target.x():
        return [
          { x: sourceRightX, y: sourceY },
          { x: sourceRightX + 20, y: sourceY },
          { x: sourceRightX + 20, y: curveMidPoint },
          { x: targetX - 20, y: curveMidPoint },
          { x: targetX - 20, y: targetY },
          { x: targetX, y: targetY }
        ];
      case diffX <= diffRightX:
        return [
          { x: sourceX, y: sourceY },
          { x: sourceX - 20, y: sourceY },
          { x: sourceX - 20, y: curveMidPoint },
          { x: targetX - 20, y: curveMidPoint },
          { x: targetX - 20, y: targetY },
          { x: targetX, y: targetY }
        ];
      case diffX > diffRightX:
        return [
          { x: sourceRightX, y: sourceY },
          { x: sourceRightX + 20, y: sourceY },
          { x: sourceRightX + 20, y: curveMidPoint },
          { x: targetRightX + 20, y: curveMidPoint },
          { x: targetRightX + 20, y: targetY },
          { x: targetRightX, y: targetY }
        ];
      default:
        return [];
    }
  }

  selectCurrentBoxHandler = (box) => {
    const classesAndConnectors = this.#g
      .selectAll("g.class, path.connector")
      .attr("opacity", 0.1);

    if (this.#activeBox) {
      this.#activeBox.active(false).updateProps();
    }

    if (box === this.#activeBox) {
      this.#activeBox
        .active(false)
        .showToolbar(false)
        .updateProps();
      this.#activeBox = null;
      classesAndConnectors.attr("opacity", 1);
      return;
    }

    D3.selectAll(
      `#${box.classname()}Class, \
      [data-linked*="${box.classname()}Class"], \
      [data-from*="${box.classname()}Class"], \
      [data-to*="${box.classname()}Class"]`
    ).attr("opacity", 1);

    box.active(true).showToolbar(true).updateProps();

    this.#activeBox = box;
  }

  mdTempConn = (box) => {
    const points = this.getConnectorPoints(
      this.#activeBox,
      box
    );

    this.#tempConnector = this.createConnectors([{
      from: this.#activeBox.classname(),
      to: box.classname(),
      points,
    }], 'temp-connector')
  }

  rmTempConn = () => {
    this.#tempConnector?.remove();
  }

  setBoxesDefaultOpacity() {
    this.#g.selectAll("g.class").attr("opacity", 1);
    this.#g.selectAll("path.connector").attr("opacity", 1);
  }

  setMode(mode, options) {
    if (!(mode in this.MODES)) return false;

    if (this.#mode) this.#modeDisabler();

    this.#mode = mode;

    ({
      [this.MODES.SELECT]: () => {
        _.forIn(this.#boxes, box => {
          box.selection().on(
            "click",
            () => this.selectCurrentBoxHandler(box)
          );
        });

        this.#modeDisabler = () => {
          _.forIn(this.#boxes, box => {
            box.selection().on('click', null);
          });
          this.setBoxesDefaultOpacity();
        };
      },
      [this.MODES.LINK]: () => {
        this.#activeBox.showToolbar(false).updateProps();

        _.forIn(this.#boxes, box => {
          box.selection()
            .on('mouseover', () => {
              this.filterByNotLinked(this.mdTempConn)(box)
            })
            .on('mouseout', () => {
              this.filterByNotLinked(this.rmTempConn)(box)
            })
            .on('click', () => {
              this.filterByNotLinked(options.linkHandler)(box)
            });
        });

        this.#g.selectAll(
          `[data-linked*="${this.#activeBox.classname()}Class"], path.connector`
        ).attr("opacity", 0.1);

        this.#modeDisabler = () => {
          _.forIn(this.#boxes, box => box.selection()
            .on('mouseover', null)
            .on('mouseout', null)
            .on('click', null)
          );

          this.setBoxesDefaultOpacity();
          this.rmTempConn();

          if (!this.#activeBox) return;

          this.#activeBox.showToolbar(true).updateProps();
        }
      },
    })[mode]();
  }

  filterByNotLinked = (callback) => (box) => {
    const activeId = this.#activeBox.data()._id;
    const boxId = box.data()._id;

    const isLinked = this.#refs.some(ref => {
      return 2 === _.chain(ref)
        .pick(['table_id', 'ref_table_id'])
        .values()
        .intersection([activeId, boxId])
        .size()
        .value();
    });

    if (!isLinked && activeId != boxId) {
      callback(box);
    }
  }
};
