import _ from 'lodash';

function api(strings, ...keys) {
  return (values, test=false) => {
    if (keys.length && !_.isPlainObject(values) && !test) {
      throw new Error('must be object');
    }

    const result = [strings[0]];

    keys.forEach(function (key, i) {
      if (_.isUndefined(key)) {
        throw new Error('key of route is undefined');
      }

      if (_.isFunction(key)) {
        result.push(key(values, test));
      } else if (!test || key in values) {
        result.push(values[key]);
      } else {
        result.push('{', key, '}');
      }

      result.push(strings[i + 1]);
    });

    return result.join('');
  }
}

export const projects = api`v2/projects`;
export const projectById = api`${projects}/${"projectId"}`;
export const regMigrations = api`${projectById}/registries/migrations`;
export const regTables = api`${projectById}/registries/tables`;
export const regTableById = api`${regTables}/${"tableId"}`;
export const tableViews = api`${regTableById}/views`;
export const tableColumns = api`${regTableById}/columns`;
export const tableColumnById = api`${tableColumns}/${"columnId"}`;
export const tableRefs = api`${regTableById}/refs`;
export const tableRefById = api`${tableRefs}/${"refId"}`;
