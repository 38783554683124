import _ from 'lodash';

import {
  SET_TABLES_LOADING,
  SET_TABLES,
  SET_STRUCTURE,
  SET_VIEWS_LOADING,
  SET_VIEWS,
  SET_GRAPH,
  SET_CURRENT_TABLE,
  SET_CURRENT_COLUMN,
  SET_REF_MODE,
  SET_REF_TABLE,
  SET_SEARCH_GENERATOR,
  SET_MODAL_CREATE_TABLE,
  SET_MODAL_EDIT_TABLE,
  SET_MODAL_ADD_COLUMN,
  SET_MODAL_ADD_REF,
  SET_MODAL_EDIT_COLUMN,
} from './mutation-types'

export default {
  [SET_TABLES_LOADING](state, tablesLoading) {
    state.tablesLoading = tablesLoading;
  },
  [SET_TABLES](state, tables) {
    state.tables = tables;
  },
  [SET_STRUCTURE](state, { classes, references }) {
    state.structure = {
      classes,
      references,
    };
  },
  [SET_VIEWS_LOADING](state, viewsLoading) {
    state.viewsLoading = viewsLoading;
  },
  [SET_VIEWS](state, views) {
    state.views = views;
  },
  [SET_GRAPH](state, graph) {
    state.graph = graph;
  },
  [SET_CURRENT_TABLE](state, tableName) {
    state.currentTable = _.find(state.tables, { name: tableName });

    if (!state.currentTable) {
      console.log(`${SET_CURRENT_TABLE}: can't find [${tableName}] in [store.registries.tables]`);
    }
  },
  [SET_CURRENT_COLUMN](state, columnId) {
    state.currentColumn = _.find(
      state.currentTable.columns,
      { _id: columnId }
    );

    if (!state.currentColumn) {
      console.log(`can't find column_id [${columnId}] \
                  in table [${state.currentTable.name}]`);
    }
  },
  [SET_REF_MODE](state, flag) {
    state.refMode = flag;
  },
  [SET_REF_TABLE](state, tableName) {
    state.refTable = _.find(state.tables, { name: tableName });

    if (!state.refTable) {
      console.log(`${SET_REF_TABLE}: can't find [${tableName}] in [store.registries.tables]`);
    }
  },
  [SET_SEARCH_GENERATOR](state, generator) {
    state.searchGenerator = generator;
  },
  [SET_MODAL_CREATE_TABLE](state, flag) {
    state.modals.createTable = flag;
  },
  [SET_MODAL_EDIT_TABLE](state, flag) {
    state.modals.editTable = flag;
  },
  [SET_MODAL_ADD_COLUMN](state, flag) {
    state.modals.createColumn = flag;
  },
  [SET_MODAL_ADD_REF](state, flag) {
    state.modals.createRef = flag;
  },
  [SET_MODAL_EDIT_COLUMN](state, flag) {
    state.modals.editColumn = flag;
  }
}
