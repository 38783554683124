import Vue from "vue";
import {SET_AUTOFIELD_LIST, SET_SELECTED_AUTOFIELD} from './mutation-types';

export const state = () => ({
  autofieldList: [],
  selected: null,
});
export const mutations = {
  [SET_AUTOFIELD_LIST](state, { list }) {
    state.autofieldList = [...list] ;
  },
  [SET_SELECTED_AUTOFIELD](state, { selected }) {
    state.selected = selected;
  },
};
export const actions = {
  async getAutofieldList({ commit }, { nodeId }) {
    const list = await this.$axios.$get("field/input/event/all", {
      params: { node_id: nodeId },
    });

    commit(SET_AUTOFIELD_LIST, { list });
  },
  removeAutofield({ commit }, { autofieldId }) {
    return this.$axios
      .$post("field/input/event/delete", {
        field_formula_id: autofieldId,
      })
      .then(() => {
        commit(SET_SELECTED_AUTOFIELD, { selected: null });
      });
  }
}
