import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";

export const state = () => ({
    submodules: null,
    allServices: null
});
export const mutations = {
    updateField,
    setSubmodules(state, submodules) {
        Vue.set(state, "submodules", submodules);
    },
    setServices(state, services) {
        Vue.set(state, "allServices", services);
    },
};
export const getters = {
    getField
};
export const actions = {
    async getSubmodules({ commit }) {
        commit('setSubmodules', await this.$axios.$get("submodule/all"))
    },
    async getServices({ commit, state }) {
        if (!state.allServices) {
            commit('setServices', await this.$axios.$get("service/all"))
        }
    }
};
