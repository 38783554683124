import {
  GET_TABLES,
  INIT_GRAPH,
  UPDATE_STRUCTURE,
  DESTROY_GRAH,
  CREATE_TABLE,
  EDIT_TABLE,
  REMOVE_TABLE,
  CREATE_COLUMN,
  EDIT_COLUMN,
  REMOVE_COLUMN,
  CREATE_MIGRATION,
  CREATE_REFERENCES,
  REMOVE_REFERENCES,
  SET_GRAPH_DEFAULT_MODE,
  SEARCH_BOX,
  SEARCH_NEXT,
  TOOLBAR_EDIT_TABLE_HANDLER,
  TOOLBAR_ADD_COLUMN_HANDLER,
  TOOLBAR_REMOVE_TABLE_HANDLER,
  TOOLBAR_CREATE_REF_HANDLER,
  COLUMN_EDIT_HANDLER,
  COLUMN_REMOVE_HANDLER,
} from "./action-types";
import {
  SET_GRAPH,
  SET_TABLES,
  SET_STRUCTURE,
  SET_TABLES_LOADING,
  SET_MODAL_EDIT_TABLE,
  SET_MODAL_ADD_COLUMN,
  SET_MODAL_EDIT_COLUMN,
  SET_MODAL_ADD_REF,
  SET_CURRENT_TABLE,
  SET_CURRENT_COLUMN,
  SET_REF_MODE,
  SET_REF_TABLE,
  SET_SEARCH_GENERATOR,
} from "./mutation-types";

import {
  ClassDiagram,
  toolbarButtons,
  columnButtons,
} from './helpers';
import {
  regTables,
  regMigrations,
  regTableById,
  tableColumns,
  tableColumnById,
  tableRefs,
  tableRefById,
} from '~~/api';


export default {
  [GET_TABLES]({ commit, rootState }) {
    if (!rootState.project.projectId) {
      throw new Error('projectId is not defined');
    }

    commit(SET_TABLES_LOADING, true);

    return this.$axios.$get(regTables({
      projectId: rootState.project.projectId,
    })).then(tables => {
        commit(SET_STRUCTURE, {
          classes: tables,
          references: tables.reduce((acc, table) => [
            ...acc,
            ...table.references
          ], [])
        });
        commit(SET_TABLES, tables);
      })
      .finally(() => commit(SET_TABLES_LOADING, false));
  },
  [INIT_GRAPH]({ dispatch, commit, state }, { el }) {
    if (!state.structure) return;

    const graph = new ClassDiagram()
      .wrapper(el)
      .toolbarButtons(toolbarButtons(dispatch))
      .columnButtons(columnButtons(dispatch))
      .classes(state.structure.classes)
      .refs(state.structure.references);

    graph.draw();

    commit(SET_GRAPH, graph);
  },
  [UPDATE_STRUCTURE]({ dispatch, state }) {
    dispatch(GET_TABLES).then(() => {
      state.graph.classes(state.structure.classes)
        .refs(state.structure.references)
        .draw();
    });
  },
  [DESTROY_GRAH]({ state }) {
    state.graph.destroy();
  },
  [CREATE_TABLE]({ dispatch, rootState }, {
    name,
    title,
    pk_name,
    pk_title
  }) {
    return this.$axios.$post(
      regTables({ projectId: rootState.project.projectId }),
      { name, title, pk_name, pk_title }
    ).then(() => dispatch(UPDATE_STRUCTURE));
  },
  [EDIT_TABLE]({ dispatch, state, rootState }, { title }) {
    return this.$axios.$patch(
      regTableById({
        projectId: rootState.project.projectId,
        tableId: state.currentTable._id,
      }),
      { title }
    ).then(() => dispatch(UPDATE_STRUCTURE));
  },
  [REMOVE_TABLE]({ dispatch, rootState, state }) {
    return this.$axios.$delete(
      regTableById({
        projectId: rootState.project.projectId,
        tableId: state.currentTable._id,
      })
    ).then(() => dispatch(UPDATE_STRUCTURE));
  },
  [CREATE_COLUMN]({ dispatch, rootState, state }, {
    name,
    title,
    type,
    is_unique,
  }) {
    return this.$axios.$post(
      tableColumns({
        projectId: rootState.project.projectId,
        tableId: state.currentTable._id,
      }),
      { name, title, type, is_unique }
    ).then(() => dispatch(UPDATE_STRUCTURE));
  },
  [EDIT_COLUMN]({ dispatch, rootState, state }, { title }) {
    return this.$axios.$patch(
      tableColumnById({
        projectId: rootState.project.projectId,
        tableId: state.currentTable._id,
        columnId: state.currentColumn._id,
      }),
      { title }
    ).then(() => dispatch(UPDATE_STRUCTURE));
  },
  [REMOVE_COLUMN]({ dispatch, rootState, state }) {
    return this.$axios.$delete(
      tableColumnById({
        projectId: rootState.project.projectId,
        tableId: state.currentTable._id,
        columnId: state.currentColumn._id,
      })
    ).then(() => dispatch(UPDATE_STRUCTURE));
  },
  [CREATE_MIGRATION]({ rootState }) {
    return this.$axios.$post(regMigrations({
      projectId: rootState.project.projectId,
    }));
  },
  [CREATE_REFERENCES]({ dispatch, commit, rootState, state }, {
    ref_column_name,
    ref_column_title
  }) {
    const ref_table_id = state.refTable._id;

    return this.$axios.$post(
      tableRefs({
        projectId: rootState.project.projectId,
        tableId: state.currentTable._id,
      }),
      { ref_table_id, ref_column_name, ref_column_title }
    ).then(() => {
      commit(SET_REF_MODE, false);
      dispatch(UPDATE_STRUCTURE);
    });
  },
  [REMOVE_REFERENCES]({ dispatch, rootState, state }, { refId }) {
    return this.$axios.$delete(
      tableRefById({
        projectId: rootState.project.projectId,
        tableId: state.currentTable._id,
        refId
      })
    ).then(() => dispatch(UPDATE_STRUCTURE));
  },
  [SET_GRAPH_DEFAULT_MODE]({ commit, state }) {
    commit(SET_REF_MODE, false);
    state.graph.setMode(state.graph.MODES.SELECT);
  },
  [SEARCH_BOX]({ commit, dispatch, state }, { search }) {
    if (!search) return;

    commit(SET_SEARCH_GENERATOR, state.graph.searchGenerator(search));

    dispatch(SEARCH_NEXT);
  },
  [SEARCH_NEXT]({ commit, state }) {
    const { value, done } = state.searchGenerator.next();

    if (!done) return state.graph.zoomToBox(value);

    commit(SET_SEARCH_GENERATOR, null);

    return this._vm.$bvModal.msgBoxOk('Ничего не найдено', {
      title: 'Сообщение',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'primary',
      headerClass: 'p-2 border-bottom-0',
      footerClass: 'p-2 border-top-0',
      centered: true
    });
  },

  [TOOLBAR_EDIT_TABLE_HANDLER]({ commit }, { classBox }) {
    commit(SET_CURRENT_TABLE, classBox.classname());
    commit(SET_MODAL_EDIT_TABLE, true);
  },
  [TOOLBAR_ADD_COLUMN_HANDLER]({ commit }, { classBox }) {
    commit(SET_CURRENT_TABLE, classBox.classname());
    commit(SET_MODAL_ADD_COLUMN, true);
  },
  [TOOLBAR_CREATE_REF_HANDLER]({ commit, state }, { classBox }) {
    commit(SET_CURRENT_TABLE, classBox.classname());
    commit(SET_REF_MODE, true);

    const mode = state.graph.MODES.LINK;
    state.graph.setMode(mode, {
      linkHandler: (refClassBox) => {
        commit(SET_REF_TABLE, refClassBox.classname());
        commit(SET_MODAL_ADD_REF, true);
      }
    });
  },
  [TOOLBAR_REMOVE_TABLE_HANDLER]({ dispatch, commit }, { classBox }) {
    commit(SET_CURRENT_TABLE, classBox.classname());

    this._vm.$bvModal.msgBoxConfirm(
      `Подтвердите удаление таблицы ${classBox.classname()}`,
      {
        title: 'Подтверждение',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Подтвердить',
        cancelTitle: 'Отменить',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }
    )
      .then(async value => {
        if (value) {
          await dispatch(REMOVE_TABLE);
        }
      })
      .catch(console.log);
  },
  [COLUMN_EDIT_HANDLER]({ commit }, { attributeBox }) {
    commit(SET_CURRENT_TABLE, attributeBox.classname());
    commit(SET_CURRENT_COLUMN, attributeBox.data()._id);
    commit(SET_MODAL_EDIT_COLUMN, true);
  },
  [COLUMN_REMOVE_HANDLER]({ dispatch, commit }, { attributeBox }) {
    commit(SET_CURRENT_TABLE, attributeBox.classname());
    commit(SET_CURRENT_COLUMN, attributeBox.data()._id);

    this._vm.$bvModal.msgBoxConfirm(
      `Подтвердите удаление колонки ${attributeBox.data().name} в таблице ${attributeBox.classname()}`,
      {
        title: 'Подтверждение',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Подтвердить',
        cancelTitle: 'Отменить',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }
    )
      .then(async value => {
        if (value) {
          await dispatch(REMOVE_COLUMN);
        }
      })
      .catch(console.log);
  },
};
