export function setAttr(selection, attributes) {
  Object.entries(attributes).forEach(([name, value]) => {
    selection.attr(name, value);
  });
  return selection;
}

export function transformTables(tables) {
  const tObj = _.chain(tables).keyBy((t) => t._id).value();

  const references = tables.reduce((acc, table) => [
    ...acc,
    ...table.references.map(r => ({
      ...r,
      source: tObj[r.ref_table_id].name,
      target: tObj[r.table_id].name,
    }))
  ], []);

  return {
    classes: tables,
    references
  };
}
