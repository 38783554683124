import { GET_PROJECT_INFO } from "./action-types";
import {
  SET_PROJECT_INFO_LOADING,
  SET_PROJECT_ID,
  SET_CURRENT_PROJECT,
} from "./mutation-types";

import { projectById } from '~~/api';


export default {
  [GET_PROJECT_INFO]({ commit }, { projectId }) {
    commit(SET_PROJECT_INFO_LOADING, true);
    commit(SET_PROJECT_ID, projectId);

    return this.$axios.$get(projectById({ projectId }))
      .then(project => {
        commit(SET_CURRENT_PROJECT, project);
      })
      .finally(() => commit(SET_PROJECT_INFO_LOADING, false));
  },
};
