import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";

export const state = () => ({
  field: {
    name: null,
    required: true,
    next_wses: [],
    type_id: null,
    alias: null
  },
  catalogs: null,
  types: null,
  nodeChilds: null,
  catalogs: null,
  types: null,
  inputFields: [],
  layers: null,
  registries: null,
  currentRegistryAttributes: [],
  v2layers: [],
  currentV2Layer: null
});
export const mutations = {
  updateField,
  setRegistryLangValue(state, { locale, value }) {
    if (!state.field.hasOwnProperty("registry_lang_values")) {
      Vue.set(state.field, "registry_lang_values", {});
    }

    Vue.set(state.field.registry_lang_values, locale, value);
  },
  setInputField(state, field) {
    state.field = field;
  },
  setCurrentRegistryAttributes(state, attributes) {
    state.currentRegistryAttributes = attributes;
  },
  setCatalogs(state, catalogs) {
    Vue.set(state, "catalogs", catalogs);
  },
  setRegistries(state, registries) {
    Vue.set(state, "registries", registries);
  },
  setV2Layers(state, layers) {
    state.v2layers = layers;
  },
  setCurrentV2Layer(state, layer) {
    state.currentV2Layer = layer;
  },
  setTypes(state, types) {
    state.types = types;
  },
  setInputFields(state, inputFields) {
    state.inputFields = inputFields;
  },
  setNodeChilds(state, nodeChilds) {
    state.nodeChilds = nodeChilds;
  },
  setColl(state, cols) {
    if (!state.field.columns) {
      Vue.set(state.field, "columns", []);
    }
    state.field.columns.push(cols);
  },
  editTableColKey(state, col) {
    Vue.set(state.field.columns[col.index], "key", col.value);
  },
  editTableColLabel(state, col) {
    Vue.set(state.field.columns[col.index], "label", col.value);
  },
  editTableColType(state, col) {
    Vue.set(state.field.columns[col.index], "type", col.value);
  },
  editTableColCatalog(state, col) {
    Vue.set(state.field.columns[col.index], "catalog", col.value);
  },
  editTableColRegistryCatalog(state, col) {
    Vue.set(state.field.columns[col.index], "registry_catalog", col.value);
  },
  editTableColRegistryCatalogLangValue(state, col) {

    if (!state.field.columns[col.index].registry_catalog_lang_values) {
      Vue.set(state.field.columns[col.index], 'registry_catalog_lang_values', {});
    }

    Vue.set(
      state.field.columns[col.index].registry_catalog_lang_values,
      col.lang,
      col.value
    );
  },
  deleteTableCol(state, index) {
    state.field.columns.splice(index, 1);
  },
  addRequestProperty(state) {
    Vue.set(state.field, "request", {});
    Vue.set(state.field.request, "route", null);
  },
  addRequestParam(state) {
    if (!state.field.request.params) Vue.set(state.field.request, "params", []);
    state.field.request.params.push({
      param: null,
      field_id: null,
      min: null
    });
  },
  removeRequestParam(state, index) {
    state.field.request.params.splice(index, 1);
  },
  editParamMin(state, val) {
    Vue.set(state.field.request.params[val.index], "min", val.value);
  },
  editParamFieldId(state, val) {
    Vue.set(state.field.request.params[val.index], "field_id", val.value);
  },
  editParamName(state, val) {
    Vue.set(state.field.request.params[val.index], "param", val.value);
  },
  setNodeId(state, val) {
    Vue.set(state.field, "node_id", val);
  },
  setOrder(state, val) {
    Vue.set(state.field, "order", val);
  },
  resetField(state) {
    Vue.set(state, "field", {
      name: null,
      next_wses: [],
      required: true,
      type_id: null,
      order: null,
      input_group_id: null
    });
  },
  setLayers(state, layers) {
    Vue.set(state, "layers", layers);
  }
};
export const getters = {
  getField
};
export const actions = {
  async getLayers({ commit }) {
    commit("setLayers", await this.$axios.$get("layer/lower"));
  }
};
