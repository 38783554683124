export const INIT_GRAPH = 'INIT_MAP';
export const UPDATE_STRUCTURE = 'UPDATE_STRUCTURE';
export const DESTROY_GRAH = 'DESTROY_GRAH';
export const GET_TABLES = 'GET_TABLES';
export const CREATE_TABLE = 'CREATE_TABLE';
export const EDIT_TABLE = 'EDIT_TABLE';
export const GET_VIEWS = 'GET_VIEWS';
export const REMOVE_TABLE = 'REMOVE_TABLE';
export const CREATE_COLUMN = 'CREATE_COLUMN';
export const EDIT_COLUMN = 'EDIT_COLUMN';
export const REMOVE_COLUMN = 'REMOVE_COLUMN';
export const CREATE_MIGRATION = 'CREATE_MIGRATION';
export const CREATE_REFERENCES = 'CREATE_REFERENCES';
export const REMOVE_REFERENCES = 'REMOVE_REFERENCES';
export const SET_GRAPH_DEFAULT_MODE = 'SET_GRAPH_DEFAULT_MODE';
export const SEARCH_BOX = 'SEARCH_BOX';
export const SEARCH_NEXT = 'SEARCH_NEXT';

export const TOOLBAR_EDIT_TABLE_HANDLER = 'TOOLBAR_EDIT_TABLE_HANDLER';
export const TOOLBAR_ADD_COLUMN_HANDLER = 'TOOLBAR_ADD_COLUMN_HANDLER';
export const TOOLBAR_REMOVE_TABLE_HANDLER = 'TOOLBAR_REMOVE_TABLE_HANDLER';
export const TOOLBAR_CREATE_REF_HANDLER = 'TOOLBAR_CREATE_REF_HANDLER';

export const COLUMN_REMOVE_HANDLER = 'COLUMN_REMOVE_HANDLER';
export const COLUMN_EDIT_HANDLER = 'COLUMN_EDIT_HANDLER';
