
export default () => ({
  projectId: null,
  tablesLoading: false,
  tables: null,
  structure: null,
  viewsLoading: false,
  views: null,
  graph: null,
  currentTable: null,
  currentColumn: null,
  refMode: null,
  refTable: null,
  searchGenerator: null,
  modals: {
    editTable: false,
    createTable: false,
    createColumn: false,
    createRef: false,
    editColumn: false,
  }
});
