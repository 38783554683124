// formula.js
export const SET_AVAILABLE_OPERATIONS = 'SET_AVAILABLE_OPERATIONS';
export const SET_AVAILABLE_VARIABLES = 'SET_AVAILABLE_VARIABLES';
export const REMOVE_EXPRESSION = 'REMOVE_EXPRESSION';
export const SET_FORMULA = 'SET_FORMULA';
export const SET_BUFFERED_VARS = 'SET_BUFFERED_VARS';
export const ADD_VARS_TO_BUFFER = 'ADD_VARS_TO_BUFFER';
export const UPDATE_BUFFERED_VAR = 'UPDATE_BUFFERED_VARS';
export const REMOVE_BUFFERED_VARS = 'REMOVE_BUFFERED_VAR';
export const RESET_FORMULA_STATE = 'RESET_FORMULA_STATE';
export const SET_ERRORS = 'SET_ERRORS';

//autofields.js
export const SET_AUTOFIELD_LIST = 'SET_AUTOFIELD_LIST';
export const SET_SELECTED_AUTOFIELD = 'SET_SELECTED_AUTOFIELD';
