import { getField, updateField } from "vuex-map-fields";


export const state = () => ({
    modals: {
        inputFieldCreateModal: false,
        inputFieldUpdateModal: false
    }
})
export const mutations = {
    updateField,
    openModal(state, ModalName) {
        state.modals[ModalName] = true;
    },
    closeModal(state, ModalName) {
        state.modals[ModalName] = false;
    },
}
export const getters = {
    getField
};
