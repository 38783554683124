import Vue from 'vue';
import _ from "lodash";

import {
  SET_AVAILABLE_OPERATIONS,
  SET_AVAILABLE_VARIABLES,
  REMOVE_EXPRESSION,
  SET_FORMULA,
  SET_BUFFERED_VARS,
  ADD_VARS_TO_BUFFER,
  UPDATE_BUFFERED_VAR,
  REMOVE_BUFFERED_VARS,
  RESET_FORMULA_STATE,
  SET_ERRORS,
} from './mutation-types';

const getDefaultState = () => ({
  operationsLoaded: false,
  availableOperations: {},
  availableVariables: [],
  expressions: [],
  resultsBuffer: {},
  errors: {
    general: [],
    operations: {},
  }
});

const getDataByPath = (expressions, path) => {
  let defaultVal = _.last(path) && !Number.isNaN(Number(_.last(path))) ? {} : [];

  if (!path.length) {
    defaultVal = expressions;
  }

  const result = _.get(expressions, path, defaultVal);

  return Array.isArray(result) ? result.filter(i => i) : result;
}

const typeMapper = (type) => {
  switch (type) {
    case 'string':
    case 'text':
      return ['number', 'text', 'string', 'objectID', 'catalog', 'date', 'registry_catalog', 'boolean'];
    case 'boolean':
      return ['bool', 'boolean', 'checkbox', 'number', 'text', 'string', 'array'];
    case 'geometry':
    case 'point':
    case 'polygon':
    case 'line':
    case 'geometry_catalog':
      return ['geometry', 'point', 'polygon', 'line', 'geometry_catalog'];
    case 'registry_catalog':
      return ['registry_catalog', 'number', 'geo_catalog_v2'];
    case 'geo_catalog_v2':
      return ['geo_catalog_v2', 'number', 'registry_catalog'];
    case 'number':
      return ['geo_catalog_v2', 'registry_catalog', 'number'];
    default:
      return [type];
  }
}

const setByPath = (collection, path, content) => {
  let buffer = collection;

  if (Array.isArray(path)) {
    const initPath = _.initial(path);
    const target = _.last(path);

    initPath.forEach(p => {
      buffer = buffer?.[p];
    })

    if (buffer) {
      Vue.set(buffer, target, content);

      return true;
    }
  }
}


export const state = getDefaultState();

export const getters = {
  //Method-Style Access
  getCurrentData: (state) => (path) => getDataByPath(state.expressions, path),
  getAllVariables: (state) => (expId, type, column = false) => {
    if (!Array.isArray(type)) {
      type = typeMapper(type);
    }

    let ids = [], terminate = false;

    const collectBufferIds = (arr) => {
      if (terminate) return;

      _.each(arr, value => {
        if (terminate) return;

        if (value?.id === expId) {
          terminate = true;
          return;
        }

        if (value?.id && value.type) {
          const opClass = state.availableOperations[value.type]['class'];

          if (opClass !== 'nested') {
            ids.push(value.id);
            return;
          }
        }

        if (_.isObject(value)) {
          collectBufferIds(value);
        }
      });
    };

    collectBufferIds(state.expressions);

    const bufferedVariables = _.keys(state.resultsBuffer)
      .filter(vn => {
        return ids.includes(vn);
      })
      .reduce((prev, id) => [
        ...prev,
        ..._.values(state.resultsBuffer[id]).filter(i => !i.notShow),
      ], []);

    return [
      ...state.availableVariables,
      ...bufferedVariables
    ].filter(i => {
      if (column === 'only' && i.field === 'column') {
        return true;
      }

      if (column === false && i.field !== 'column') {
        return true;
      }

      if (column === true) {
        return true;
      }

      return false;
    }).filter(i => _.intersection(i.type, type).length && i.name);
  }
};

export const mutations = {
  [SET_AVAILABLE_OPERATIONS](state, { operations }) {
    state.availableOperations = operations;
    state.operationsLoaded = true;
  },
  [SET_AVAILABLE_VARIABLES](state, { variables }) {
    state.availableVariables = variables;
  },
  [REMOVE_EXPRESSION](state, { path }) {
    if (_.isString(path)) {
      path = _.toPath(path);
    }

    const idForRemove = _.last(path);
    const initialPath = _.initial(path);

    const arr = getDataByPath(state.expressions, initialPath);
    _.remove(arr, (__, index) => index == idForRemove);
    if (!initialPath.length) {
      state.expressions = arr;
      return;
    }
    _.set(state.expressions, initialPath, arr);
  },
  [SET_FORMULA](state, { path, content = [] }) {
    if (!path.length) {
      state.expressions = content;
      return;
    }

    setByPath(state.expressions, path, content);
  },
  [SET_BUFFERED_VARS](state, { content }) {
    state.resultsBuffer = content;
  },
  [ADD_VARS_TO_BUFFER](state, { id, content }) {
    state.resultsBuffer = {
      ...state.resultsBuffer,
      [id]: content
    };
  },
  [UPDATE_BUFFERED_VAR](state, { id, originName, name, type }) {
    if (!(id in state.resultsBuffer)) {
      return;
    }

    const prevResult = _.get(state.resultsBuffer, [id, originName]);

    state.resultsBuffer[id] = {
      ...(state.resultsBuffer[id] || {}),
      [originName]: {
        ...state.resultsBuffer[id][originName],
        name: name ?? prevResult?.name,
        type: type ?? prevResult?.type
      }
    };

    state.resultsBuffer[id] = _.keys(state.resultsBuffer[id])
      .filter(i => i.includes(`${originName} → `))
      .reduce((acc, colOrigin) => ({
        ...acc,
        [colOrigin]: {
          ...state.resultsBuffer[id][colOrigin],
          table: name,
          title: name + state.resultsBuffer[id][colOrigin]
            .title.replace(/.*(→.*)/, ' $1'),
          name: name + state.resultsBuffer[id][colOrigin]
            .name.replace(/.*(\..*)/, '$1'),
        }
      }), state.resultsBuffer[id]);
  },
  [REMOVE_BUFFERED_VARS](state, { id }) {
    if (!(id in state.resultsBuffer)) {
      return;
    }

    _.unset(state.resultsBuffer, id)
  },
  [RESET_FORMULA_STATE](state) {
    const {
      availableOperations,
      availableVariables,
      operationsLoaded
    } = state;
    Object.assign(state, getDefaultState(), {
      availableOperations,
      availableVariables,
      operationsLoaded
    });
  },
  [SET_ERRORS](state, {general = [], operations = {}}) {
    state.errors = { general, operations };
  }
};
export const actions = {
  async getAvailableOperations({ commit, state }, { url }) {
    this.$axios
      .$get(url)
      .then(response => {
        commit(SET_AVAILABLE_OPERATIONS, {operations: response});
      })
      .catch(() => {
        commit(SET_ERRORS, {
          general: [
            ...state.errors.general,
            'Не удалось запросить операции',
          ]
        });
      });
  },
  async getAvailableVariables({ commit }, { url }) {
    this.$axios
      .$get(url)
      .then(response => {
        commit(SET_AVAILABLE_VARIABLES, {variables: response});
      })
      .catch(() => {
        commit(SET_ERRORS, {
          general: [
            ...state.errors.general,
            'Не удалось запросить переменные',
          ]
        });
      });
  },
  async createNewExpression({commit, state, getters }, {expressionType, path}) {
    const currentData = getters.getCurrentData(path);
    const inVars = state.availableOperations[expressionType].input;
    const outVars = state.availableOperations[expressionType].output;

    const operationClass = state.availableOperations[expressionType]['class'];

    if (!_.isObject(inVars) || !(operationClass === 'nested' || _.isObject(outVars))) {
      alert(`Ошибка в метаданных операции ${expressionType}`);
      return;
    }

    const maxId = Math.max(...currentData.map(o => o.id.split('.').pop()));

    const id = [...path, isFinite(maxId) ? maxId + 1 : 0].join('.');

    const expressionInVars = _.keys(inVars)
      .reduce((prev, varName) => ({
        ...prev,
        [varName]: { isCustom: false, value: ''}
      }), {});

    let expressionObj = {
      id,
      type: expressionType,
      variables: { input: expressionInVars },
    };

    if (operationClass !== 'nested') {
      const expressionOutVars = _.keys(outVars)
        .reduce((prev, varName) => ({
          ...prev,
          [varName]: ''
        }), {});

      const bufferVars = _.keys(outVars)
        .reduce((prev, varName) => {
          let colVars = {};

          if (outVars[varName].columns) {
            colVars = _.entries(outVars[varName].columns)
              .reduce((acc, [colName, colType]) => ({
                ...acc,
                [`${varName} → ${colName}`]: {
                  title: ` → ${colName}`,
                  table: varName,
                  field: 'column',
                  columnKey: colName,
                  name: `.${colName}`,
                  notShow: expressionType === 'changeField',
                  type: _.isArray(colType) ? colType : [colType],
                }
              }), colVars);
          }

          return {
            ...prev,
            [varName]: {
              name: '',
              notShow: expressionType === 'changeField',
              type: outVars[varName].type,
            },
            ...colVars,
          };
        }, {});

      commit(ADD_VARS_TO_BUFFER, { id, content: bufferVars });

      expressionObj['variables']['output'] = expressionOutVars;
    }

    commit(SET_FORMULA, {
      path: [...path],
      content: [
        ...currentData,
        expressionObj,
      ],
    });
  },
  setNewFormulaStructure({ state, commit }, { formula }) {
    commit(RESET_FORMULA_STATE);

    if (!Array.isArray(formula)) {
      return;
    }

    const extractOutVar = (arr, acc = {}) => {
      arr.reduce((acc, exp) => {
        if (_.isPlainObject(exp?.variables?.output) && exp.type !== 'if') {
          const op = state.availableOperations[exp.type];

          acc[exp.id] = _.reduce(
            exp?.variables?.output,
            (vars, outValue, outKey) => {
              let colVars = {};

              if (_.isPlainObject(op.output?.[outKey]?.columns)) {
                colVars = _.entries(op.output[outKey].columns)
                  .reduce((acc, [colName, colType]) => ({
                    ...acc,
                    [`${outKey} → ${colName}`]: {
                      title: `${outValue} → ${colName}`,
                      table: outValue,
                      field: 'column',
                      columnKey: colName,
                      name: `${outValue}.${colName}`,
                      notShow: exp.type === 'changeField',
                      type: _.isArray(colType) ? colType : [colType],
                    }
                  }), colVars);
              }

              return {
                ...vars,
                [outKey]: {
                  name: outValue,
                  notShow: exp.type === 'changeField',
                  type: op.output?.[outKey]?.type,
                },
                ...colVars,
              }
            },
            {}
          );
        }

        if (exp.type === 'if') {
          if (Array.isArray(exp?.trueBlock)) {
            extractOutVar(exp.trueBlock, acc);
          }

          if (Array.isArray(exp.falseBlock)) {
            extractOutVar(exp.falseBlock, acc);
          }
        }

        if (exp.type === 'tableOperation' && Array.isArray(exp?.tableBlock)) {
            extractOutVar(exp.tableBlock, acc);
        }

        return acc;
      }, acc);

      return acc;
    }

    const bufferVar = extractOutVar(formula);
    commit(SET_BUFFERED_VARS, { content: bufferVar });
    commit(SET_FORMULA, { path: [], content: formula });
  },
  removeExpression({ commit, getters }, { path }) {
    const data = getters.getCurrentData(path);
    commit(REMOVE_BUFFERED_VARS, { id: data.id });
    commit(REMOVE_EXPRESSION, { path });
  }
};
